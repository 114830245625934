import Markdown from "react-markdown";
import changelog from "../../../CHANGELOG.md?raw";
import { DetailsCard } from "../DetailsCard";

export const ReleaseNotesPage = () => (
	<DetailsCard title="Release Notes">
		<div style={{ padding: "0 20px" }}>
			<Markdown>{changelog}</Markdown>
		</div>
	</DetailsCard>
);
