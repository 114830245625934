import { useQuery } from "@tanstack/react-query";
import { environment } from "../../libs/environments/src/environment/environment";
import { DetailsCard } from "../DetailsCard";
import { DGrid } from "../display/DGrid";

const STATUS = {
	LOADING: "🕒 Loading...",
	ONLINE: "🟢 Online",
	OFFLINE: "🔴 Offline",
};

export const ServerStatusPage = () => {
	const networkStatus = useQuery({
		queryKey: ["networkStatus"],
		initialData: STATUS.LOADING,
		queryFn: () => (navigator.onLine ? STATUS.ONLINE : STATUS.OFFLINE),
	});

	const jobServerStatus = useQuery({
		queryKey: ["jobServerStatus"],
		initialData: STATUS.LOADING,
		queryFn: () =>
			fetch(environment.jobApiUrl).then((res) =>
				res.status === 404 ? STATUS.ONLINE : STATUS.OFFLINE,
			),
	});

	const authenticationServerStatus = useQuery({
		queryKey: ["authenticationServerStatus"],
		initialData: STATUS.LOADING,
		queryFn: () =>
			fetch(environment.authenticationApiUrl).then((res) =>
				res.status === 404 ? STATUS.ONLINE : STATUS.OFFLINE,
			),
	});

	const reportServerStatus = useQuery({
		queryKey: ["reportServerStatus"],
		initialData: STATUS.LOADING,
		queryFn: () =>
			fetch(environment.reportApiUrl).then((res) =>
				res.status === 404 ? STATUS.ONLINE : STATUS.OFFLINE,
			),
	});

	return (
		<DGrid columns="1fr 1fr">
			<DGrid rows="1fr 1fr">
				<DetailsCard title="Network Status">
					<p>{networkStatus.data}</p>
				</DetailsCard>
				<DetailsCard title="Job Server Status">
					<p>{jobServerStatus.data}</p>
				</DetailsCard>
			</DGrid>
			<DGrid rows="1fr 1fr">
				<DetailsCard title="Authentication Server Status">
					<p>{authenticationServerStatus.data}</p>
				</DetailsCard>
				<DetailsCard title="Report Server Status">
					<p>{reportServerStatus.data}</p>
				</DetailsCard>
			</DGrid>
		</DGrid>
	);
};
