import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import type { ReactNode } from "react";
import { DFlex } from "./display/DFlex";

type DetailsCardProps = {
	title: ReactNode;
	buttons?: ReactNode;
	children: ReactNode;
};
export const DetailsCard = ({ title, buttons, children }: DetailsCardProps) => {
	return (
		<Card>
			<CardHeader>
				<DFlex spaceBetween>
					<h3 style={{ alignContent: "center" }}>{title}</h3>
					{buttons && (
						<DFlex gap="8px" vCenter>
							{buttons}
						</DFlex>
					)}
				</DFlex>
			</CardHeader>
			<CardBody style={{ height: "0", overflow: "auto" }}>{children}</CardBody>
		</Card>
	);
};
