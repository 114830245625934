import { useMemo } from "react";
import type { MoveActions } from "../../new/api/JobApi";
import { GenericDataGrid } from "../../new/grids/GenericDataGrid";
import type { GenericGridProps, TypedGridColumnProps } from "../../new/helpers";
import { MoveStatusCell } from "../../new/helpersReact";

export type MoveGrid = {
	actions: MoveActions;
	status: number;
	statusName: string;
	id: number;
	uniqueId: string;
	typeName: string;
	fromAddress: string;
	toAddress: string;
	assignedHaulierId: number;
	assignedHaulierName: string;
	distance: number;
	isNew?: boolean;
};
export type MoveGridProps = GenericGridProps<MoveGrid> & {
	extraColumns?: TypedGridColumnProps<MoveGrid>[];
};
export type MoveGridActions = {
	actions: MoveActions;
	status: number;
	statusName: string;
	id: number;
	uniqueId: string;
	type: number;
	typeName: string;
	fromAddress: string;
	toAddress: string;
	assignedHaulierId: number;
	assignedHaulierName: string;
	distance: number;
	isNew?: boolean;
};
export type MoveGridActionsProps = GenericGridProps<MoveGridActions> & {
	extraColumns?: TypedGridColumnProps<MoveGridActions>[];
};

const defaultColumns: TypedGridColumnProps<MoveGrid>[] = [
	{
		field: "statusName",
		title: "Status",
		cell: ({ dataItem }) => (
			<MoveStatusCell
				status={dataItem.status}
				name={dataItem.statusName}
				isNew={dataItem.isNew}
				title={`${dataItem.statusName}${
					dataItem.isNew ? " (Need Review)" : ""
				}`}
			/>
		),
	},
	{ field: "uniqueId", title: "ID" },
	{ field: "typeName", title: "Type" },
	{ field: "fromAddress", title: "From" },
	{ field: "toAddress", title: "To" },


	{
		field: "distance",
		title: "Distance",
	},
];

export const MoveGrid = ({
	extraColumns = [],
	...rest
}: GenericGridProps<MoveGrid>) => {
	const columns = useMemo(
		() => [...defaultColumns, ...extraColumns],
		[extraColumns],
	);
	return <GenericDataGrid defaultColumns={columns} name="Orders" {...rest} />;
};
